import { basicClient } from "@/graphql/client/basic-client";
import { userClient } from "@/graphql/client/user-client";
import {
  claimOrigin,
  claimProfit,
  estMint,
  estMintUser,
  getClaim,
  getClaimSolxSettings,
  getStakeInfo,
  having,
  news,
  permission,
  price24h,
  priceChart,
  saveStake,
  sessionTelegram,
  settingFeeIn,
  stakeCycle,
  stakeHistory,
  statistic,
} from "@/graphql/query/homeStatictis";
import useSolana from "@/hook/useSolana";
import { isDeviceLaptop } from "../../wallet/solana/Solana";
import store from "@/store";
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";
import {
  provideApolloClient,
  useMutation,
  useQuery,
} from "@vue/apollo-composable";
import { publicKey, struct, u8, u64, blob } from "@raydium-io/raydium-sdk";
import axios from "axios";
import { SUBSCRIPTION_TYPE } from "@/common/const";
import { useWallet } from "solana-wallets-vue";
const token_fee: any = process.env.VUE_APP_TOKEN_FEE;
const cluster: any = process.env.VUE_APP_CLUSTER;

const TokenSwapLayout = struct([
  u8("version"),
  u8("isInitialized"),
  u8("bumpSeed"),
  publicKey("poolTokenProgramId"),
  publicKey("tokenAccountA"),
  publicKey("tokenAccountB"),
  publicKey("tokenPool"),
  publicKey("mintA"),
  publicKey("mintB"),
  publicKey("authority"),
  publicKey("feeAccount"),
  u64("tradeFeeNumerator"),
  u64("tradeFeeDenominator"),
  u64("ownerTradeFeeNumerator"),
  u64("ownerTradeFeeDenominator"),
  u64("ownerWithdrawFeeNumerator"),
  u64("ownerWithdrawFeeDenominator"),
  u64("hostFeeNumerator"),
  u64("hostFeeDenominator"),
  u8("curveType"),
  blob(32, "curveParameters"),
]);
const rpc_mainnet: any = process.env.VUE_APP_RPC_MAINNET;
const rpc_mainnet_2: any = process.env.VUE_APP_RPC_MAINNET_2;
const rpc_devnet: any = process.env.VUE_APP_RPC_DEVNET;

export const SWAP_PROGRAM_ID = new PublicKey(
  "FLUXubRmkEi2q6K3Y9kBPg9248ggaZVsoSFhtJHSrm1X"
);
export const getAddress = () => {
  let address = null;
  if (isDeviceLaptop() && !store.state.isTelegram) {
    const { publicKey } = useWallet();

    address = publicKey.value?.toString() || null;
  } else {
    address = store.state.sessionSolana?.public_key?.toString() || null;
  }
  return address;
};
export const getClaimInfo = async () => {
  try {
    const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
      useQuery(getClaim)
    );
    onResult(() => {
      //console.log("claim ", result.value?.getClaim?.result);
      store.commit("setLog", { reward: result.value?.getClaim?.result });

      store.commit("setInfoReward", result.value?.getClaim?.result);
    });
  } catch (err) {
    store.commit("setLog", { err });

    //console.log("🚀 ~ getClaim ~ err:", err);
  }
};

export const getSolBalance = async (address: string, decimals = 9) => {
  if (!address) return;
  try {
    let connection;
    if (cluster == "devnet") {
      connection = new Connection(rpc_devnet, "confirmed");
    } else {
      connection = new Connection(rpc_mainnet, "confirmed");
    }

    const balance = await connection.getBalance(new PublicKey(address));
    console.log(balance / Math.pow(10, decimals));
    return balance / Math.pow(10, decimals);
  } catch (err) {
    console.log(err);
  }
};
// export const getBalanceSplToken = async (
//   mintToken: string,
//   address: string
// ) => {
//   if (!address) return [{ mintToken, tokenBalance: 0 }];
//   let connection;
//   if (cluster == "devnet") {
//     connection = new Connection(rpc_devnet, "confirmed");
//   } else {
//     connection = new Connection(rpc_mainnet, "confirmed");
//   }

//   const filters = [
//     {
//       dataSize: 165, //size of account (bytes)
//     },
//     {
//       memcmp: {
//         offset: 32, //location of our query in the account (bytes)
//         bytes: new PublicKey(address) as any, //our search criteria, a base58 encoded string
//       },
//     },
//   ];
//   if (mintToken) {
//     filters.push({
//       memcmp: {
//         offset: 0, //number of bytes
//         bytes: mintToken, //base58 encoded string
//       },
//     });
//   }

//   const accounts = await connection.getParsedProgramAccounts(
//     TOKEN_PROGRAM_ID, //new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")
//     { filters: filters }
//   );
//   //console.log("🚀 ~ getBalanceSplToken ~ accounts:", accounts);

//   const infoTokens: any[] = [];

//   accounts.forEach((account, i) => {
//     //Parse the account data
//     const parsedAccountInfo: any = account.account.data;
//     const mintAddress = parsedAccountInfo["parsed"]["info"]["mint"];
//     const tokenBalance =
//       parsedAccountInfo["parsed"]["info"]["tokenAmount"]["uiAmount"];
//     //Log results
//     infoTokens.push({
//       mintAddress,
//       tokenBalance,
//     });
//   });

//   return infoTokens;
// };
export const getBalanceSplToken = async (
  mintToken: string,
  address: string
) => {
  if (!address) return [{ mintToken, tokenBalance: 0 }];
  let connection;
  if (cluster == "devnet") {
    connection = new Connection(rpc_devnet, "confirmed");
  } else {
    connection = new Connection(rpc_mainnet, "confirmed");
  }
  try {
    // get balance spl token not use getParsedProgramAccounts
    const tokenAccount = await getAssociatedTokenAddress(
      new PublicKey(mintToken),
      new PublicKey(address)
    );
    const balance = await connection.getTokenAccountBalance(
      new PublicKey(tokenAccount)
    );
    console.log("🚀 ~ CrawlerPriceService ~ balance:", balance);
    return [{ mintToken, tokenBalance: balance.value.uiAmount }];
  } catch (Err) {
    console.log("🚀 ~ CrawlerPriceService ~ Err:", Err);
  }
};
export const getBlanceAccountConnect = async (address: string) => {
  try {
    //console.log("get rate......");
    if (store.state.poolInfo?.mintA) {
      if (store.state.poolInfo?.mintA == token_fee) {
        const balance = (await getSolBalance(address)) || 0;
        //console.log("🚀 ~ getBlanceAccountConnect ~ balance:", balance);
        store.commit("setBalanceTokenBase", balance);
      } else {
        if (store.state.poolInfo?.mintA) {
          const balance = (
            await getBalanceSplToken(store.state.poolInfo?.mintA, address)
          )[0].tokenBalance;

          store.commit("setBalanceTokenBase", balance);
          //console.log("🚀 ~ getBlanceAccountConnect ~ balance:", balance);
        }
      }
    }

    let balanceTokenQuote;
    try {
      if (store.state.poolInfo?.mintB) {
        balanceTokenQuote = (
          await getBalanceSplToken(store.state.poolInfo?.mintB, address)
        )[0].tokenBalance;
      }
    } catch (err) {
      //console.log("🚀 ~ getBlanceAccountConnect ~ err:", err);
    }
    try {
      if (store.state.poolInfo?.mintB == store.state.poolInvestment.mint) {
        store.commit("setBalanceTokenMintStake", balanceTokenQuote);
      } else {
        const balanceTokenMintStake = (
          await getBalanceSplToken(store.state.poolInvestment.mint, address)
        )[0].tokenBalance;
        console.log(
          "🚀 ~ getBlanceAccountConnect ~ balanceTokenMintStake:",
          balanceTokenMintStake
        );
        store.commit("setBalanceTokenMintStake", balanceTokenMintStake || 0);
      }
    } catch (err) {
      //console.log("🚀 ~ getBlanceAccountConnect ~ err:", err);
    }

    store.commit("setBalanceTokenQuote", balanceTokenQuote || 0);
  } catch (err) {
    //console.log("🚀 ~ getBlanceAccountConnect ~ err:", err);
  }
};

export const getToken = () => {
  const info = window.localStorage.getItem("user-information");
  if (!info) return null;

  return JSON.parse(info).token;
};

export const getNews = (): any => {
  try {
    let res: any;
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(news)
    );
    onResult(() => {
      res = result.value?.news?.result;
    });

    return res;
  } catch (err) {
    return err;
  }
};
export const stakeHistorys = () => {
  if (!store.state.user?.token) {
    store.commit("setReceipt", null);

    return;
  }
  try {
    const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
      useQuery(stakeHistory)
    );
    onResult(() => {
      store.commit("setReceipt", result.value?.stakeHistory?.result);
      // console.log(
      //   "🚀 ~ onResult ~ result: stakeHistorys",
      //   result.value?.stakeHistory?.result
      // );
    });
    refetch();
  } catch (err) {
    return err;
  }
};

export const saveStakeSolx = async (data: any) => {
  try {
    const { mutate } = provideApolloClient(userClient)(() =>
      useMutation(saveStake, {
        variables: {
          saveStakeInput: data,
        },
      })
    );
    const res = await mutate();
    //console.log(">>>>>>>>>>>>>>>>>>>>>>>>connect be res", res);
    getBalanceUser();
  } catch (err) {
    //console.log("🚀 ~ saveStakeSolx ~ err:", err);
  }
};

export const getStakeCycle = async () => {
  try {
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(stakeCycle)
    );
    onResult(() => {
      // console.log(
      //   "🚀 ~ onResult ~ result.value?.stakeCycle.result:",
      //   result.value?.stakeCycle?.result
      // );
      store.commit("setStakeCycle", result.value?.stakeCycle?.result);
    });

    refetch();
  } catch (err) {
    //console.log("🚀 ~ saveStakeSolx ~ err:", err);
  }
};

export const getBalanceUser = async () => {
  try {
    //console.log("🚀 ~ getBalanceUser ~ isDeviceLaptop():", isDeviceLaptop());
    const solana = useSolana();

    if (isDeviceLaptop()) {
      if (solana?.wallet?.publicKey) {
        getBlanceAccountConnect(solana?.wallet?.publicKey.toString());
      }
    } else {
      if (solana?.session?.public_key) {
        getBlanceAccountConnect(solana?.session?.public_key.toString());
      }
    }
  } catch (err) {
    //console.log("🚀 ~ getBalanceUser ~ err:", err);
  }
};

export const getPoolStake = (userId: number | null = null) => {
  try {
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(getStakeInfo)
    );
    onResult(() => {
      // console.log(
      //   "🚀 ~ onResult ~ result.value?.stakeInfo.result:",
      //   result.value?.stakeData?.result
      // );
      store.commit(
        "setPoolStakeInfo",
        result.value?.stakeData?.result?.poolStakeData
      );
      store.commit(
        "setPoolInvestment",
        result.value?.stakeData?.result?.poolInvestmentData
      );
    });

    refetch();
  } catch (err) {
    //console.log("🚀 ~ saveStakeSolx ~ err:", err);
  }
};

export const saveClaimProfit = async (data: any) => {
  try {
    const { mutate } = provideApolloClient(userClient)(() =>
      useMutation(claimProfit, {
        variables: {
          claimStakeInput: data,
        },
      })
    );
    const res = await mutate();
    //console.log(">>>>>>>>>>>>>>>>>>>>>>>>saveClaimProfit res", res);
    getBalanceUser();
  } catch (err) {
    //console.log("🚀 ~ saveClaimProfit ~ err:", err);
  }
};

export const saveClaimOrigin = async (data: any) => {
  try {
    const { mutate } = provideApolloClient(userClient)(() =>
      useMutation(claimOrigin, {
        variables: {
          claimStakeInput: data,
        },
      })
    );
    const res = await mutate();
    //console.log(">>>>>>>>>>>>>>>>>>>>>>>>saveClaimOrigin res", res);
    getBalanceUser();
  } catch (err) {
    //console.log("🚀 ~ saveClaimOrigin ~ err:", err);
  }
};

export const getSwapPools = async (tokenA: PublicKey, tokenB: PublicKey) => {
  const connection = new Connection(rpc_mainnet, "confirmed");

  const resp = await connection.getProgramAccounts(SWAP_PROGRAM_ID, {
    commitment: "confirmed",
    filters: [
      {
        memcmp: {
          offset: 1 + 1 + 1 + 32 + 32 + 32 + 32,
          bytes: tokenA.toString(),
        },
      },
      {
        memcmp: {
          offset: 1 + 1 + 1 + 32 + 32 + 32 + 32 + 32,
          bytes: tokenB.toString(),
        },
      },
    ],
  });
  const respInverse = await connection.getProgramAccounts(SWAP_PROGRAM_ID, {
    commitment: "confirmed",
    filters: [
      {
        memcmp: {
          offset: 1 + 1 + 1 + 32 + 32 + 32 + 32,
          bytes: tokenB.toString(),
        },
      },
      {
        memcmp: {
          offset: 1 + 1 + 1 + 32 + 32 + 32 + 32 + 32,
          bytes: tokenA.toString(),
        },
      },
    ],
  });
  return resp.concat(respInverse).map((m) => {
    return {
      pubkey: m.pubkey,
      account: TokenSwapLayout.decode(m.account.data),
    };
  });
};
export const getPoolDetail = async (pool: any) => {
  //console.log("🚀 ~ getPoolDetail ~ pool:", pool);
  // //console.log("🚀 ~ getPoolDetail ~ pool:", {
  //   pubkey: pool.pubkey.to
  // });

  const connection = new Connection(rpc_mainnet, "confirmed");

  const resp = await connection.getMultipleParsedAccounts([
    pool.account.tokenAccountA,
    pool.account.tokenAccountB,
  ]);
  //console.log("🚀 ~ getPoolDetail ~ resp:", resp);
  // //console.log("LP Mint:", pool.tokenPool.toString())

  return {
    tokenAccountA: (resp?.value[0]?.data as any).parsed?.info,
    tokenAccountB: (resp?.value[1]?.data as any).parsed?.info,
  };
};
export const secondsToDays = (seconds: number) => {
  const days = seconds / (60 * 60 * 24);
  const hour = seconds / (60 * 60);
  const minute = seconds / 60;

  if (days >= 30) {
    const months = days / 30;
    return (months == 1 ? "" : months.toFixed(0)) + " month";
  } else if (days >= 7) {
    const weeks = days / 7;
    return (weeks == 1 ? "" : weeks.toFixed(0)) + " week";
  } else if (days >= 1) {
    return (days == 1 ? "" : days.toFixed(0)) + " day";
  } else if (hour >= 1) {
    return Number(hour).toFixed(0) + " hour";
  } else if (minute >= 1) {
    return Number(minute).toFixed(0) + " minute";
  } else {
    return Number(seconds).toFixed(0) + " seconds";
  }
};

export const convertSeconds = (seconds: number) => {
  const days = Math.floor(seconds / (24 * 3600));
  const hours = Math.floor((seconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: remainingSeconds,
  };
};
export const fetchPrice24h = () => {
  try {
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(price24h)
    );
    onResult(() => {
      store.commit("setPrice24h", result.value?.price24h?.result);
    });

    refetch();
  } catch (err) {
    //console.log("🚀 ~ fetchPrice24h ~ err:", err);
  }
};

export const fetchDataChart = () => {
  try {
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(statistic)
    );
    onResult(() => {
      console.log(
        "🚀 ~ onResult ~ fetchDataChart",
        result.value?.statistic?.result
      );

      store.commit("setDataChart", result.value?.statistic?.result);
    });

    refetch();
  } catch (err) {
    //console.log("🚀 ~ statistic ~ err:", err);
  }
};

export const fetchHaving = () => {
  try {
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(having)
    );
    onResult(() => {
      //console.log("🚀 ~ onResult ~ fetchHaving", result.value);
      const halvingValue =
        (result.value?.halvingInfo?.currentSolx /
          (Number(result.value?.halvingInfo?.next_target) || 10000000)) *
          100 || 0;

      store.commit("setHaving", result.value?.halvingInfo);
      store.commit("setHavingValue", halvingValue);
      store.commit("setHavingLogs", result.value?.halvingInfo?.halvingLogs);
    });

    refetch();
  } catch (err) {
    //console.log("🚀 ~ statistic ~ err:", err);
  }
};

export const callCrawler = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: process.env.VUE_APP_ENPOINT_CRAWLER,
      headers: {
        Authorization: "Basic " + process.env.VUE_APP_AUTH_CRAWLER,
      },
      data,
    });
    window.localStorage.setItem("id_process", res?.data.processId);
  } catch (err) {
    //console.log("🚀 ~ callCrawler ~ err:", err);
  }
};

export const rejectCrawler = async () => {
  try {
    const id = window.localStorage.getItem("id_process");
    if (!id) {
      //console.log("no id process");

      return;
    }
    await axios({
      method: "POST",
      url: `${process.env.VUE_APP_ENPOINT_REJECT_CRAWLER}/${id}`,
      headers: {
        Authorization: "Basic " + process.env.VUE_APP_AUTH_CRAWLER,
      },
    });
    window.localStorage.removeItem("id_process");
  } catch (err) {
    //console.log("🚀 ~ callCrawler ~ err:", err);
  }
};

export const fetchPermission = () => {
  try {
    const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
      useQuery(permission)
    );
    onResult(() => {
      store.commit("setPermission", result.value?.featurePermission);
    });

    refetch();
  } catch (err) {
    //console.log("🚀 ~ fetchPermission ~ err:", err);
  }
};

export const fetchSettingAddToken = () => {
  try {
    const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
      useQuery(getClaimSolxSettings)
    );
    onResult(() => {
      store.commit(
        "setAddTokenSetting",
        result.value?.getClaimSolxSettings?.result
      );
    });

    refetch();
  } catch (err) {
    //console.log("🚀 ~ fetchSettingAddToken ~ err:", err);
  }
};

// export const boostFromMint = async (value: string) => {
//   try {
//     const { mutate } = provideApolloClient(userClient)(() =>
//       useMutation(boostFromMint, {
//         variables: {
//           claimStakeInput: data,
//         },
//       })
//     );
//     const res = await mutate();
//     //console.log(">>>>>>>>>>>>>>>>>>>>>>>>saveClaimOrigin res", res);
//     getBalanceUser();
//   } catch (err) {
//     //console.log("🚀 ~ saveClaimOrigin ~ err:", err);
//   }
// };
export const checkNumber = (number: number) => {
  if (number % 2 === 0) {
    return true;
  } else {
    return false;
  }
};

export const getRpcConnection = (): Connection => {
  try {
    let connection: Connection;
    if (checkNumber(new Date().getTime())) {
      //console.log("rpc_1");

      connection = new Connection(rpc_mainnet, {
        commitment: "confirmed",
        confirmTransactionInitialTimeout: 80000,
      });
    } else {
      //console.log("rpc_2");

      connection = new Connection(rpc_mainnet_2, {
        commitment: "confirmed",
        confirmTransactionInitialTimeout: 80000,
      });
    }
    return connection;
  } catch (err) {
    //console.log("🚀 ~ getRpcConnection ~ err:", err);
  }
};

export const formatDate = (seconds: number, onlyDate = false) => {
  const inputDateStr = new Date(Number(seconds) * 1000);
  // //console.log("🚀 ~ formatDate ~ inputDateStr:", inputDateStr);

  // const date = new Date(inputDateStr);

  const formattedDate = inputDateStr.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });

  const formattedTime = inputDateStr.toTimeString().split(" ")[0];

  const result = `${formattedDate} - ${formattedTime}`;
  if (onlyDate) {
    return formattedDate;
  }
  return result;
};
//

export const getSettingFeeIn = async () => {
  try {
    const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
      useQuery(settingFeeIn)
    );
    onResult(() => {
      //console.log("🚀 ~ onResult ~ getSettingFeeIn", result.value);
      store.commit("setDataSettingFeeIne", result.value?.getUserFee?.fee_in);
    });

    refetch();
  } catch (err) {
    //console.log("🚀 ~ saveClaimOrigin ~ err:", err);
  }
};
export const isFeeIn = (type: string) => {
  let result = false;
  const dataSettingFeeIn: any = store.state.dataSettingFeeIn;
  switch (type) {
    case SUBSCRIPTION_TYPE.CLAIM:
      result = Object.hasOwn(dataSettingFeeIn, SUBSCRIPTION_TYPE.CLAIM);
      break;
    case SUBSCRIPTION_TYPE.SWAP:
      result = Object.hasOwn(dataSettingFeeIn, SUBSCRIPTION_TYPE.SWAP);
      break;
    case SUBSCRIPTION_TYPE.CLAIM_BONUS:
      result = Object.hasOwn(dataSettingFeeIn, SUBSCRIPTION_TYPE.CLAIM_BONUS);
      break;
    case SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE:
      result = Object.hasOwn(
        dataSettingFeeIn,
        SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE
      );
      break;
    case SUBSCRIPTION_TYPE.CLAIM_INTEREST:
      result = Object.hasOwn(
        dataSettingFeeIn,
        SUBSCRIPTION_TYPE.CLAIM_INTEREST
      );
      break;
    case SUBSCRIPTION_TYPE.CLAIM_PRINCIPLE:
      result = Object.hasOwn(
        dataSettingFeeIn,
        SUBSCRIPTION_TYPE.CLAIM_PRINCIPLE
      );
      break;
    case SUBSCRIPTION_TYPE.LOAN:
      result = Object.hasOwn(dataSettingFeeIn, SUBSCRIPTION_TYPE.LOAN);
      break;
    case SUBSCRIPTION_TYPE.CLAIM_SOLX_INIT:
      result = Object.hasOwn(
        dataSettingFeeIn,
        SUBSCRIPTION_TYPE.CLAIM_SOLX_INIT
      );
      break;
    case SUBSCRIPTION_TYPE.INVESTMENT:
      result = Object.hasOwn(dataSettingFeeIn, SUBSCRIPTION_TYPE.INVESTMENT);
      break;
    case SUBSCRIPTION_TYPE.CLAIM_INVESTMENT_INTEREST:
      result = Object.hasOwn(
        dataSettingFeeIn,
        SUBSCRIPTION_TYPE.CLAIM_INVESTMENT_INTEREST
      );
      break;
    case SUBSCRIPTION_TYPE.CLAIM_INVESTMENT_PRINCIPLE:
      result = Object.hasOwn(
        dataSettingFeeIn,
        SUBSCRIPTION_TYPE.CLAIM_INVESTMENT_PRINCIPLE
      );
      break;
    default:
      break;
  }
  return result;
};

export const estInterestMint = async (amount: string) => {
  try {
    if (Number(amount) <= 0) return;
    store.commit("setEstMint", "loading");
    let res: any;
    if (store.state.user?.token) {
      const { mutate } = provideApolloClient(userClient)(() =>
        useMutation(estMintUser, {
          variables: {
            input: amount,
          },
        })
      );
      res = await mutate();
    } else {
      const { mutate } = provideApolloClient(basicClient)(() =>
        useMutation(estMint, {
          variables: {
            input: amount,
          },
        })
      );
      res = await mutate();
    }

    //console.log(">>>>>>>>>>>>>>>>>>>>>>>>estInterestMint res", res);
    store.commit("setEstMint", res?.data?.estClaim || res?.data?.estClaimUser);
  } catch (err) {
    //console.log("🚀 ~ saveClaimProfit ~ err:", err);
  }
};

export const generateTypeMemo = (
  type: string,
  fee?: "in" | "out" | null,
  autoSub?: boolean | null,
  optionWithdraw?: "all" | "available" | null,
  amountBoost?: number | string | null
) => {
  try {
    let result = `t:${type}`;
    if (fee) {
      result += `--f:${fee}`;
    }
    if (autoSub != null) {
      result += `--a:${autoSub}`;
    }
    if (optionWithdraw) {
      result += `--o:${optionWithdraw}`;
    }
    if (amountBoost) {
      result += `--n:${amountBoost}`;
    }
    return result;
  } catch (err) {
    //console.log("🚀 ~ err:", err);
  }
};

export const getSessionTelegram = async (telegram_id: string) => {
  try {
    const { mutate } = provideApolloClient(basicClient)(() =>
      useMutation(sessionTelegram, {
        variables: {
          telegramId: telegram_id,
        },
      })
    );
    const res = await mutate();
    //console.log("🚀 ~ getSessionTelegram ~ res:", res);
    return res;
  } catch (err) {
    //console.log("🚀 ~ saveClaimOrigin ~ err:", err);
  }
};

export const getWalletAddressAll = () => {
  let address;
  const solana = useSolana();

  if (isDeviceLaptop() && !store.state.isTelegram) {
    const { publicKey } = useWallet();
    address = publicKey.value?.toString();
  } else {
    address = solana.session?.public_key.toString();
  }
  return address;
};

export const getPriceChart = async (fromDate: string, toDate: string) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { mutate } = provideApolloClient(basicClient)(() =>
      useMutation(priceChart, {
        variables: {
          priceChartInput: {
            fromDate: fromDate,
            timezone: "UTC",
            toDate: toDate,
          },
        },
      })
    );
    const res = await mutate();
    return res;
  } catch (err) {
    console.log("🚀 ~ getPriceChart ~ err:", err);
  }
};
